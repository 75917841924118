<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择"
            clearable
            width="100px"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机">
            <el-input
              placeholder="请输入手机号"
              v-model.trim="queryForm.phone"
            ></el-input>
          </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加用户表单</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="OneCourseList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="类型" prop="language_type" align="center"></el-table-column>
        <el-table-column label="用户手机号" prop="userInfo.phone" align="center"></el-table-column>
        <el-table-column label="用户课时总币" prop="total_score" align="center"></el-table-column>
        <el-table-column label="剩余课时币" prop="left_score" align="center"></el-table-column>
        <el-table-column label="老师" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.language_type == '法语'">未绑定</div>
            <div v-else>{{scope.row.teacherInfo.username}}</div>
          </template>
        </el-table-column>
        <el-table-column label="绑定课程" prop="goodsInfo.goods_name" align="center"></el-table-column>
        <el-table-column label="开通时间" prop="create_time" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加用户表单 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="用户" label-width="100px" v-if="isAdd == 'add'">
            <!-- <el-input
              v-model="addForm.userPhone"
              placeholder="请输入手机号"
            /> -->
            <el-select style="width:50%" filterable remote  clearable :remote-method="searchUser" v-loadmore="loadMore" v-model="addForm.userPhone"  placeholder="请选择用户">
              <el-option :label="`${item.phone}——${item.nickname}`" :value=item.phone v-for="item in userArr" :key="item.phone"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item el-form-item label="类型" label-width="100px">
          <el-select
            v-model="addForm.create_type"
            placeholder="请选择类型"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in options"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="老师" label-width="100px" v-if="addForm.create_type != 6">
          <el-select
            v-model="addForm.teacherId"
            placeholder="请选择老师"
            clearable
          >
            <el-option
              :label="item.username"
              :value="item.id"
              v-for="item in selectTeacher"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item el-form-item label="课程id" label-width="100px">
          <el-select
            v-model="addForm.goods_id"
            placeholder="请输入选择课程id"
            clearable
            filterable
          >
            <el-option
              :label="item.id"
              :value="item.id"
              v-for="item in selectCourse"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item el-form-item label="课程id" label-width="100px">
            <el-input
              v-model="addForm.goods_id"
              placeholder="请输入选择课程id"
            />
        </el-form-item>
        <el-form-item el-form-item label="用户课时总币" label-width="100px" v-if="isAdd == 'add'">
            <el-input
              v-model="addForm.total_score"
              placeholder="请输入总课时币"
            />
        </el-form-item>
        <el-form-item el-form-item label="剩余课时币" label-width="100px" v-else>
            <el-input
              v-model="addForm.left_score"
              placeholder="请输入剩余课时币"
            />
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  getOneCourseList,
  oneCourseAdd,
  oneCourseEdit,
} from "@/api/oneToOne.js";
import {
  getUserList,
} from "@/api/userApi";
import {
  getCourseList,
} from "@/api/courseApi";
import {getRole} from "@/api/rolelist.js";
import {SelectUser} from "@/api/order";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        type: 1,
        phone: null,
      },
      OneCourseList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        total_score: null,
        left_score: null,
        teacherId: [],
        userPhone: "",
        create_type: null,
        goods_id: null,
      },
      addtitle: "",
      isAdd: "add",
      teacherProps: {
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      options: [
        {
          value: 4,
          label: "日语"
        },
        {
          value: 5,
          label: "韩语"
        },
        {
          value: 6,
          label: "法语"
        },
      ],
      selectTeacher: [],
      selectCourse: [],
      listId: null,
      typeList: [
        {
          value: 1,
          label: "日语"
        },
        {
          value: 2,
          label: "韩语"
        },
        {
          value: 3,
          label: "法语"
        },
      ],
      userArr: [],
    };
  },

  created() {
    this.getOneCourseList();
    this.getUserListData();
  },
  watch: {
    "addForm.create_type": function (news, old) {
      this.options.forEach((item) => {
        if (item.value == news) {
          this.getTeacherListData(item.value);
          this.getCourseData(item.value);
          return;
        }
      });
    },
  },
  methods: {
    // 获取老师列表
    getTeacherListData(mold) {
      getRole({role:mold}).then(res => {
        if(mold == 4){
          this.selectTeacher = res.data.data;
          this.selectTeacher.unshift({id:2,username:"shino"})
        }else{
          this.selectTeacher = res.data.data;
        }
      });
    },
    // 获取课程列表
    getCourseData(mold) {
      getCourseList({page:1,limit:10,cate_id:mold-3}).then(res => {
        this.selectCourse = res.data.data;
      });
    },
    // 一对一用户列表
    getOneCourseList() {
      getOneCourseList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        let arr = [];
        res.data.data.map((item,index)=>{
          if(item.create_type == 1){
            arr.push(
              Object.assign(item,{language_type: "日语"})
            )
          }else if(item.create_type == 2){
            arr.push(
              Object.assign(item,{language_type: "韩语"})
            )
          }else if(item.create_type == 3){
            arr.push(
              Object.assign(item,{language_type: "法语"})
            )
          }
        })
        this.OneCourseList = arr;
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加用户表单";
      this.addDialog = true;
      // this.searchUser();
    },

    // 获取用户列表
    getUserListData() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        this.userTotal = res.data.total;
        this.userArr = res.data.data;
      });
    },
    // 选择用户触底
    loadMore() {
      let data = {
        page: Math.ceil(this.userArr.length / 10) + 1,
        limit: 10,
      };
      SelectUser(data).then((res) => {
        if (this.userTotal < Math.ceil(this.userArr.length / 10) + 1) {
          return;
        }
        this.userArr = this.userArr.concat(res.data.data);
      });
    },
    // 用户搜索
    searchUser(query) {
      let data = {
        page: 1,
        limit: 10,
        phone: query,
      };
      SelectUser(data).then((res) => {
        this.userArr = res.data.data;
      });
    },

    // 添加一对一
    addNow() {
      if (this.addForm.userPhone == "") {
        this.$message.error("请输入手机号码");
        return;
      } else if (this.addForm.create_type == null) {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.create_type != 6 && this.addForm.teacherId == null) {
        this.$message.error("请选择老师");
        return;
      } else if (this.addForm.goods_id == null) {
        this.$message.error("请选择课程id");
        return;
      } else if (this.addForm.total_score == null) {
        this.$message.error("请输入总课程币");
        return;
      }
      this.addForm.userPhone = this.addForm.userPhone.split(" ")[1]
      getUserList({phone:this.addForm.userPhone}).then((res) => {
        if(res.data.data.length == 0){
          this.$message.error("该手机号码还没有注册!");
          return
        }
        if(res.data.data[0].id){
          let addData = {
            user_id: res.data.data[0].id,
            total_score: Number(this.addForm.total_score),
            admin_teacher_id: this.addForm.teacherId,
            create_type: Number(this.addForm.create_type) - 3,
            goods_id: this.addForm.goods_id
          }
          oneCourseAdd(addData).then((res) => {
            if (res.code !== 1) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("添加一对一用户成功！");
            this.addDialog = false;
            this.queryForm.type = Number(this.addForm.create_type) - 3;
            this.getOneCourseList();
          });
        }
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑一对一用户信息";
      this.addDialog = true;
      this.addForm.left_score = item.left_score;
      this.addForm.userPhone = item.userInfo.phone;
      this.addForm.teacherId = item.teacherInfo.id;
      this.addForm.create_type = Number(item.create_type) + 3;
      this.addForm.goods_id = item.goods_id;
      this.listId = item.id;
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.userPhone == "") {
        this.$message.error("请输入手机号码");
        return;
      } else if (this.addForm.create_type == null) {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.create_type != 6 && this.addForm.teacherId == null) {
        this.$message.error("请选择老师");
        return;
      } else if (this.addForm.goods_id == null) {
        this.$message.error("请选择课程id");
        return;
      } else if (this.addForm.left_score == null) {
        this.$message.error("请输入剩余课程币");
        return;
      }
      let addData = {
        id: this.listId,
        left_score: Number(this.addForm.left_score),
        admin_teacher_id: Number(this.addForm.teacherId),
        create_type: Number(this.addForm.create_type) - 3,
        goods_id: this.addForm.goods_id
      }
      oneCourseEdit(addData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改一对一用户成功！");
        this.addDialog = false;
        this.getOneCourseList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        total_score: null,
        left_score:null,
        teacherId: null,
        create_type: null,
        userPhone: "",
        goods_id: null
      };
      this.selectTeacher = []
      this.selectCourse = []
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getOneCourseList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getOneCourseList() ;
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getOneCourseList() ;
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 200px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
