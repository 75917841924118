import request from "@/utils/request";

export function addCourse(data){
    return request({
        url:'/admin/base/goodsAdd',
        method:'post',
        data
        }
    )
}

export function courseUpdate(data){
    return request({
        url:'/admin/base/goodsEdit',
        method:'put',
        data
        }
    )
}
export function selectCourseList(data) {
  return request({
      url:'/admin/base/goodsCateList',
      method:'get',
      params: data
      }
  )
}

export function getCateList(data) {
  return request({
      url:'/admin/base/goodsCateList',
      method:'get',
      params: data
      }
  )
}

export function selectCourseCate(data){
    return request({
        url:'/admin/base/selectGoodsCate',
        method:'get',
        params: data
        }
    )
}

export function delCourse(id){
    return request({
        url:'/admin/base/goodsDel',
        method:'delete',
        params: {id}
        }
    )
}

export function getCourseList(query){
    return request({
        url:'/admin/base/goodsList',
        method:'get',
        params: query
        }
    )
}

export function delMoreCourse(data){
    return request({
        url:'/admin/base/goodsDel',
        method:'put',
        params: data
        }
    )
}

export function getTeacherList(query){
    return request({
        url:'/admin/base/selectTeacher',
        method:'get',
        params: query
        }
    )
}

export function getGoodsDetails(query){
    return request({
        url:'/admin/base/goodsDetails',
        method:'get',
        params: query
        }
    )
}

// 小节排序
export function goodsChapterOrder(data){
    return request({
        url:'/admin/base/goodsChapterOrder',
        method:'put',
        data
        }
    )
}

// 章节排序
export function goodsLibOrder(data){
    return request({
        url:'/admin/base/goodsLibOrder',
        method:'put',
        data
        }
    )
}

// 答题详情
export function examAnsDetailByVideo(query){
    return request({
        url:'/admin/french/examAnsDetailByVideo',
        method:'get',
        params:query
        }
    )
}