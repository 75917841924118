import request from "@/utils/request";

//一对一用户列表
export function getOneCourseList(data) {
  return request({
      url: "/admin/base/oneCourseList",
      method: "get",
      params: data
  });
}
// 添加一对一用户
export function oneCourseAdd(data) {
  return request({
      url: "/admin/base/oneCourseAdd",
      method: "post",
      data
  });
}
//修改一对一用户
export function oneCourseEdit(data) {
  return request({
      url: "/admin/base/oneCourseEdit",
      method: "put",
      data
  });
}